import request from '@/utils/request'

const api = {
  playerList: '/game/mai2/player/list',
  playerDetail: '/game/mai2/player/detail',
  playerGameVersion: '/game/mai2/player/gameVersion/update',
  playerUsername: '/game/mai2/player/username/update',
  playerBonusDate: '/game/mai2/player/bonusDate/update',
  playerCharaSlot: '/game/mai2/player/charaSlot/update',
  playerNetMember: '/game/mai2/player/netMember/update',
  playerCardList: '/game/mai2/player/card/list',
  playerCardSave: '/game/mai2/player/card/update',
  playerCardDelete: '/game/mai2/player/card/delete',
  playerItemList: '/game/mai2/player/item/list',
  playerItemSave: '/game/mai2/player/item/update',
  playerItemDelete: '/game/mai2/player/item/delete',
  playerCardCurrent: '/game/mai2/player/card/current',
  playerCardSet: '/game/mai2/player/card/set',
  playerCardUnset: '/game/mai2/player/card/unset',
  playerCharacterList: '/game/mai2/player/character/list',
  playerPhotoList: '/game/mai2/player/photoLog/list',
  playerMusicDetailList: '/game/mai2/player/musicDetail/list',
  playerPlaylogList: '/game/mai2/player/playlog/list',
  playerImport: '/game/mai2/player/import',
  playerExport: '/game/mai2/player/export',
  playerDelete: '/game/mai2/player/physicallyDelete',
  gameMusicList: '/game/mai2/data/music/list',
  gameMusicSave: '/game/mai2/data/music/update',
  gameMusicDelete: '/game/mai2/data/music/delete',
  gameEventList: '/game/mai2/data/event/list',
  gameEventSave: '/game/mai2/data/event/update',
  gameEventDelete: '/game/mai2/data/event/delete',
  gameEventTemplateList: '/game/mai2/data/event/template/list',
  gameEventTemplateSave: '/game/mai2/data/event/template/update',
  gameEventTemplateDelete: '/game/mai2/data/event/template/delete',
  gameMachineConfigList: '/game/mai2/data/machine/list',
  gameMachineConfigSave: '/game/mai2/data/machine/update',
  gameMachineConfigDelete: '/game/mai2/data/machine/delete'
}

export default api

export function getPlayerList (parameter) {
  return request({
    url: api.playerList,
    method: 'get',
    params: parameter
  })
}

export function getPlayerDetail (parameter) {
  return request({
    url: api.playerDetail,
    method: 'get',
    params: parameter
  })
}

export function setPlayerGameVersion (parameter) {
  return request({
    url: api.playerGameVersion,
    method: 'post',
    params: parameter
  })
}

export function setPlayerUsername (parameter) {
  return request({
    url: api.playerUsername,
    method: 'post',
    params: parameter
  })
}

export function setPlayerBonusDate (parameter) {
  return request({
    url: api.playerBonusDate,
    method: 'post',
    params: parameter
  })
}

export function setPlayerCharaSlot (parameter) {
  return request({
    url: api.playerCharaSlot,
    method: 'post',
    params: parameter
  })
}

export function setPlayerNetMember (parameter) {
  return request({
    url: api.playerNetMember,
    method: 'post',
    params: parameter
  })
}

export function getPlayerCardList (parameter) {
  return request({
    url: api.playerCardList,
    method: 'get',
    params: parameter
  })
}

export function getPlayerCardCurrent (parameter) {
  return request({
    url: api.playerCardCurrent,
    method: 'get',
    params: parameter
  })
}

export function savePlayerCard (parameter, data) {
  return request({
    url: api.playerCardSave,
    method: 'post',
    params: parameter,
    data
  })
}

export function deletePlayerCard (parameter) {
  return request({
    url: api.playerCardDelete,
    method: 'post',
    params: parameter
  })
}

export function setPlayerCard (parameter) {
  return request({
    url: api.playerCardSet,
    method: 'post',
    params: parameter
  })
}

export function unsetPlayerCard (parameter) {
  return request({
    url: api.playerCardUnset,
    method: 'post',
    params: parameter
  })
}

export function getPlayerCharacterList (parameter) {
  return request({
    url: api.playerCharacterList,
    method: 'get',
    params: parameter
  })
}

export function getPlayerItemList (parameter) {
  return request({
    url: api.playerItemList,
    method: 'get',
    params: parameter
  })
}

export function savePlayerItem (parameter, data) {
  return request({
    url: api.playerItemSave,
    method: 'post',
    params: parameter,
    data
  })
}

export function deletePlayerItem (parameter) {
  return request({
    url: api.playerItemDelete,
    method: 'post',
    params: parameter
  })
}

export function getPlayerPhotoList (parameter) {
  return request({
    url: api.playerPhotoList,
    method: 'get',
    params: parameter
  })
}

export function getPlayerMusicDetailList (parameter) {
  return request({
    url: api.playerMusicDetailList,
    method: 'get',
    params: parameter
  })
}

export function getPlayerPlaylogList (parameter) {
  return request({
    url: api.playerPlaylogList,
    method: 'get',
    params: parameter
  })
}

export function importPlayer (data) {
  return request({
    url: api.playerImport,
    method: 'post',
    data
  })
}

export function deletePlayer (parameter) {
  return request({
    url: api.playerDelete,
    method: 'post',
    params: parameter
  })
}

export function getPlayerExport (parameter) {
  return request({
    url: api.playerExport,
    method: 'get',
    params: parameter
  })
}

export function getMusicList (parameter) {
  return request({
    url: api.gameMusicList,
    method: 'get',
    params: parameter
  })
}

export function saveMusic (data) {
  return request({
    url: api.gameMusicSave,
    method: 'post',
    data
  })
}

export function deleteMusic (data) {
  return request({
    url: api.gameMusicDelete,
    method: 'post',
    data
  })
}

export function getEventList (parameter) {
  return request({
    url: api.gameEventList,
    method: 'get',
    params: parameter
  })
}

export function saveEvent (data) {
  return request({
    url: api.gameEventSave,
    method: 'post',
    data
  })
}

export function deleteEvent (data) {
  return request({
    url: api.gameEventDelete,
    method: 'post',
    data
  })
}

export function getEventTemplateList (parameter) {
  return request({
    url: api.gameEventTemplateList,
    method: 'get',
    params: parameter
  })
}

export function saveEventTemplate (parameter, data) {
  return request({
    url: api.gameEventTemplateSave,
    method: 'post',
    params: parameter,
    data
  })
}

export function deleteEventTemplate (parameter, data) {
  return request({
    url: api.gameEventTemplateDelete,
    method: 'post',
    params: parameter,
    data
  })
}

export function getMachineConfigList (parameter) {
  return request({
    url: api.gameMachineConfigList,
    method: 'get',
    params: parameter
  })
}

export function saveMachineConfig (data) {
  return request({
    url: api.gameMachineConfigSave,
    method: 'post',
    data
  })
}

export function deleteMachineConfig (data) {
  return request({
    url: api.gameMachineConfigDelete,
    method: 'post',
    data
  })
}
