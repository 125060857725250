<template>
  <page-header-wrapper :content="'用户ID：' + this.queryParam.playerId">
    <a-card :bordered="false">
      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
      >
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable } from '@/components'
import { getPlayerPhotoList } from '@/api/game/mai2'

export default {
  name: 'PlayerPhotoList',
  components: {
    STable
  },
  data () {
    return {
      roleFormVisible: false,
      confirmLoading: false,
      formModel: null,
      queryParam: {
        playerId: this.$route.params.playerId
      },
      loadData: parameter => {
        return getPlayerPhotoList(Object.assign(parameter, this.queryParam))
      },
      columns: [
        {
          title: '成绩ID',
          dataIndex: 'playlogId',
          sorter: true
        },
        {
          title: '曲目',
          dataIndex: 'trackNo',
          sorter: true
        },
        {
          title: '上传时间',
          dataIndex: 'uploadDate',
          sorter: true
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
